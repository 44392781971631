import React from 'react';
import {graphql} from 'gatsby';

import Layout from './components/layout'
import SEO from './components/seo'
import Slide from './components/Slide';

export const query = graphql`  
   query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
      }
  }
  allLinkedListJson {
    edges {
      node {
        id
        link
        label
        site_id
      }
    }
  }
}
`


export default ({data}) => {
    console.log(data)
    function createMarkup() {
        return {__html: data.markdownRemark.html};
      }
    return (
        <div>
            <Layout navlist={data.allLinkedListJson.edges}>
      <SEO title={data.markdownRemark.frontmatter.title} />
      <div className="container-fluid bg-secondary justify-content-center">
      <div className="text-center pt-5">
        <div className="row">
        <div className="col-7">
        <Slide />

        </div>
        <div className="col-5 pt-5">
        Perched above Avalon Bay on famed Santa Catalina Island is 166 Middle Terrace Road. This four-level estate beautifully
         blends the beauty and tranquility of the island with interior amenities. 
        </div>
        </div>
        <div className="p-5" dangerouslySetInnerHTML={createMarkup()} />
      </div>
      </div>
     
    </Layout>
        </div>
    )
}
