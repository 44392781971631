import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'


const Slide = () => {
    // const [index, setIndex] = useState(0)
  const {allFile} = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: {fields: name, order: DESC}
          filter: {relativeDirectory: {eq: "featured-properties"}}
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `,
  )
    //Minus 1 for array offset from 0
    // const length = allFile.edges.length - 1
    // const handleNext = () =>
    //   index === length ? setIndex(0) : setIndex(index + 1)
    // const handlePrevious = () =>
    //   index === 0 ? setIndex(length) : setIndex(index - 1)
    // const { node } = allFile.edges[index]
  return (
    <div className="mt-5">
      <Carousel
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
        showThumbs={false}
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
      >
        {allFile.edges.map(im => {
          return (<Img
                      style={{height:'100%'}}z
                    fluid={im.node.childImageSharp.fluid}
                    key={im.node.id}
                    alt={im.node.name.replace(/-/g,' ').substring(2)}
                  />)
        })}
          {/* <Img
            fluid={node.childImageSharp.fluid}
            key={node.id}
            alt={node.name.replace(/-/g, ' ').substring(2)}
          /> */}
      </Carousel>
      {/* <div>
        <button onClick={() => handlePrevious()}>Previous</button>
        <button onClick={() => handleNext()}>Next</button>
      </div> */}
    </div>
  )
}

export default Slide